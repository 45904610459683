import React from "react";
import { CSSPropertiesDict } from "../../helpers/types";
import fonts from "../../helpers/fonts";
import { NORD_COLORS } from "../../themes/palettes/colors";

const brand: React.CSSProperties = {
  paddingBottom: "2em",
  width: "75%",
};

const brandLtMd: React.CSSProperties = {
  paddingBottom: "1em",
  width: "100%",
};

const brandMd: React.CSSProperties = { ...brand };

const container: React.CSSProperties = {
  padding: "3em",
  textAlign: "center",
};

const containerLtMd: React.CSSProperties = {
  ...container,
  padding: "0em",
  paddingBottom: "1em",
};

const containerMd: React.CSSProperties = {
  ...container,
};

const description: React.CSSProperties = {
  padding: "0 1em",
  fontFamily: fonts.default,
  fontSize: "1.5em",
  textAlign: "justify",
};

const descriptionGridItem: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const descriptionIcon: React.CSSProperties = {
  height: "100%",
};

const illustration: React.CSSProperties = {
  aspectRatio: "30/20",
  objectFit: "cover",
  width: "100%",
};

const localeSelectorStyle: React.CSSProperties = {
  margin: "1em 0",
};

const paper: React.CSSProperties = {
  paddingBottom: "1em",
};

const footerDetails: React.CSSProperties = {
  fontFamily: fonts.default,
  textAlign: "start",
};

const footerDetailsSmall: React.CSSProperties = {
  ...footerDetails,
  textAlign: "center",
};

const footerTitle: React.CSSProperties = {
  fontFamily: fonts.title,
  textAlign: "start",
  textTransform: "uppercase",
};

const footerLogo: React.CSSProperties = {
  width: "15em",
};

const vatNumber: React.CSSProperties = {
  fontFamily: "Roboto Mono",
  padding: "0 5px",
};

const vatNumberDark: React.CSSProperties = {
  ...vatNumber,
  backgroundColor: NORD_COLORS.NORD4,
  color: NORD_COLORS.NORD0,
};

const vatNumberLight: React.CSSProperties = {
  ...vatNumber,
  backgroundColor: NORD_COLORS.NORD3,
  color: NORD_COLORS.NORD4,
};

const vatNumberContainer: React.CSSProperties = {
  cursor: "grab",
};

const style: CSSPropertiesDict = {
  brandMd,
  brandLtMd,
  containerMd,
  containerLtMd,
  description,
  descriptionGridItem,
  descriptionIcon,
  footerDetails,
  footerDetailsSmall,
  footerLogo,
  footerTitle,
  illustration,
  localeSelectorStyle,
  paper,
  vatNumberDark,
  vatNumberLight,
  vatNumberContainer,
};

export default style;
