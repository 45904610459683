import { SimplePaletteColorOptions } from "@mui/material";
import { NORD_COLORS } from "./colors";

const primary: SimplePaletteColorOptions = {
  main: NORD_COLORS.NORD10,
  contrastText: NORD_COLORS.NORD1,
};

const success: SimplePaletteColorOptions = {
  main: NORD_COLORS.NORD14,
  contrastText: NORD_COLORS.NORD0,
};

const warning: SimplePaletteColorOptions = {
  main: NORD_COLORS.NORD13,
  contrastText: NORD_COLORS.NORD0,
};

const basePalette = {
  primary,
  success,
  warning,
};

export default basePalette;
