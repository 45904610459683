import { NORD_COLORS } from "../../themes/palettes/colors";
import { CSSPropertiesDict } from "../../helpers/types";

const copyright: React.CSSProperties = {
  alignItems: "center",
  justifyContent: "center",
};

const version: React.CSSProperties = {
  color: NORD_COLORS.NORD15,
};

const style: CSSPropertiesDict = {
  copyright,
  version,
};

export default style;
