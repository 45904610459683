import {
  SimplePaletteColorOptions,
  TypeBackground,
  TypeText,
} from "@mui/material";
import { NORD_COLORS } from "./colors";
import basePalette from "./base";

const secondary: SimplePaletteColorOptions = {
  main: NORD_COLORS.NORD10,
  contrastText: NORD_COLORS.NORD6,
};

const text: Partial<TypeText> = {
  primary: NORD_COLORS.NORD0,
  secondary: NORD_COLORS.NORD6,
};

const background: TypeBackground = {
  default: NORD_COLORS.NORD6,
  paper: NORD_COLORS.NORD4,
};

const lightPalette = {
  ...basePalette,
  secondary,
  text,
  background,
};

export default lightPalette;
