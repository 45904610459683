import { ThemeContext } from "@emotion/react";
import { Trans } from "@lingui/macro";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WorkIcon from "@mui/icons-material/Work";
import { Grid2, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import darkLogo from "../../assets/pictures/logo/dark/brand-logo.png";
import lightLogo from "../../assets/pictures/logo/light/brand-logo.png";
import LocaleSelector from "../../components/locale-selector";
import ThemeSelector from "../../components/theme-selector";
import {
  CONTACT_MAIL,
  LINKED_IN_LINK,
  LINKED_IN_PSEUDO_LINK,
  VAT_NUMBER,
} from "../../helpers/consts";
import CopyToClipboardAlert from "./copy-to-clipboard.alert";
import style from "./style";

const HomePageContacts = () => {
  const { darkMode } = useContext<object>(ThemeContext) as {
    darkMode: boolean;
  };

  const theme = useTheme();
  const isLtMd = useMediaQuery(theme.breakpoints.down("md"));

  const [showAlert, setShowAlert] = useState<boolean>(false);

  const handleVATnumberClick = (): void => {
    navigator.clipboard.writeText(VAT_NUMBER);
    setShowAlert(true);
  };

  return (
    <div className="HomePageContacts">
      <Grid2 container spacing={isLtMd ? 0 : 5}>
        <Grid2 size={{ xs: 12, md: 3 }}>
          <img
            src={darkMode ? darkLogo : lightLogo}
            alt="Northenware Logo"
            style={style.footerLogo}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, md: 9 }}>
          <h2 style={style.footerTitle}>
            <Trans>Contact</Trans>
          </h2>
          <p style={style.footerDetails}>
            Northenware BV
            <Stack direction="row" spacing={1}>
              <EmailIcon />
              <div>
                <a href={"mailto:" + CONTACT_MAIL}>{CONTACT_MAIL}</a>
              </div>
            </Stack>
            <Stack direction="row" spacing={1}>
              <LinkedInIcon />
              <Trans>
                <a href={LINKED_IN_LINK}>{LINKED_IN_PSEUDO_LINK}</a>
              </Trans>
            </Stack>
            <Stack direction="row" spacing={1}>
              <WorkIcon />
              <Trans>
                <div
                  style={style.vatNumberContainer}
                  onClick={handleVATnumberClick}
                >
                  VAT number:&nbsp;
                  <span
                    style={
                      darkMode ? style.vatNumberDark : style.vatNumberLight
                    }
                  >
                    {VAT_NUMBER}
                  </span>
                </div>
              </Trans>
            </Stack>
            <br />
            <div style={style.localeSelectorStyle}>
              <Stack direction={isLtMd ? "column" : "row"} spacing={2}>
                <LocaleSelector />
                <ThemeSelector />
              </Stack>
            </div>
          </p>
        </Grid2>
      </Grid2>

      {/* Hidden components */}
      <CopyToClipboardAlert showAlert={showAlert} setShowAlert={setShowAlert} />
    </div>
  );
};

export default HomePageContacts;
