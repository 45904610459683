import { Trans } from "@lingui/macro";
import LanguageIcon from "@mui/icons-material/Language";
import {
  InputAdornment,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from "../../helpers/consts";
import { dynamicActivate, getPreferredLocale } from "../../helpers/i18n";
import style from "./style";

const LocaleSelector = () => {
  const theme = useTheme();
  const isLtMd = useMediaQuery(theme.breakpoints.down("md"));

  const [locale, setLocale] = useState<string>(DEFAULT_LOCALE);

  useEffect(() => {
    setLocale(getPreferredLocale(DEFAULT_LOCALE));
  }, []);

  const handleLocaleChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newLocale = event.target.value;
    setLocale(newLocale);

    await dynamicActivate(newLocale);
  };

  return (
    <div className="LocaleSelector">
      <TextField
        select
        label={<Trans>Select your language</Trans>}
        value={locale}
        sx={isLtMd ? style.textFieldLtMd : style.textFieldMd}
        fullWidth={isLtMd}
        onChange={handleLocaleChange}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <LanguageIcon />
              </InputAdornment>
            ),
          },
        }}
      >
        {Object.entries(SUPPORTED_LOCALES).map(([key, label]) => (
          <MenuItem key={key} value={key}>
            {label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default LocaleSelector;
