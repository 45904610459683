import { ThemeContext, ThemeProvider } from "@emotion/react";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { CssBaseline } from "@mui/material";
import { useEffect, useState } from "react";
import BaseFooter from "./components/base/footer";
import BaseHeader from "./components/base/header";
import "./fonts.css";
import { DEFAULT_LOCALE } from "./helpers/consts";
import { dynamicActivate, getPreferredLocale } from "./helpers/i18n";
import HomePage from "./pages/home";
import style from "./style";
import { isPreferredThemeDark } from "./themes/base";
import darkTheme from "./themes/dark";
import lightTheme from "./themes/light";

const App = () => {
  useEffect(() => {
    dynamicActivate(getPreferredLocale(DEFAULT_LOCALE));
  }, []);

  const [darkMode, setDarkMode] = useState<boolean>(isPreferredThemeDark());

  return (
    <I18nProvider i18n={i18n}>
      <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
          <div className="App" style={style.appStyle.defaultStyle}>
            <CssBaseline enableColorScheme />

            <header>
              <BaseHeader />
            </header>

            <body>
              <HomePage />
            </body>

            <footer>
              <BaseFooter />
            </footer>
          </div>
        </ThemeProvider>
      </ThemeContext.Provider>
    </I18nProvider>
  );
};

export default App;
