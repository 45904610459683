import { DEFAULT_TEXTFIELD_WIDTH } from "../../helpers/consts";
import { CSSPropertiesDict } from "../../helpers/types";

const textField: React.CSSProperties = {};

const textFieldMd: React.CSSProperties = {
  ...textField,
  width: DEFAULT_TEXTFIELD_WIDTH,
};

const textFieldLtMd: React.CSSProperties = {
  ...textField,
};

const style: CSSPropertiesDict = {
  textFieldLtMd,
  textFieldMd,
};

export default style;
