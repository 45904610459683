import { createTheme } from "@mui/material";
import baseTheme from "./base";
import darkPalette from "./palettes/dark";

const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: "dark",
    text: darkPalette.text,
    background: darkPalette.background,
  },
  components: {
    ...baseTheme.components,
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: darkPalette.secondary.main,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: darkPalette.secondary.main,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: darkPalette.secondary.main,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected:hover": {
            backgroundColor: darkPalette.secondary.main,
          },
          "&:hover": {
            backgroundColor: darkPalette.secondary.main,
            color: darkPalette.secondary.contrastText,
          },
        },
      },
    },
  },
});

export default darkTheme;
