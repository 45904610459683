import CopyrightIcon from "@mui/icons-material/Copyright";
import { Stack } from "@mui/material";
import style from "./style";

const CREATION_YEAR = 2024;
const currentYear: number = new Date(Date.now()).getFullYear();

const copyRightYear: number | string =
  CREATION_YEAR == currentYear ? CREATION_YEAR : (
    `${CREATION_YEAR} - ${currentYear}`
  );

const version = process.env.REACT_APP_VERSION || "Development mode";

const BaseFooter = () => {
  return (
    <div className="BaseFooter">
      <Stack direction="row" spacing={1} sx={style.copyright}>
        <span>Northenware BV</span>
        <CopyrightIcon />
        <span>{copyRightYear}</span>
        <span style={style.version}>{version}</span>
      </Stack>
    </div>
  );
};

export default BaseFooter;
